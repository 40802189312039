import getData from "@/__main__/get-data.mjs";
import ValorantStatsAgents from "@/data-models/valorant-stats-agents.mjs";
import { getStats } from "@/game-val/api.mjs";
import {
  NUMBER_OF_LEADERBOARDS_ON_HOMEPAGE,
  TOP_REGIONS,
} from "@/game-val/constants.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import fetchData from "@/game-val/fetch-leaderboard-data.mjs";
import { getStatsParamsStr } from "@/game-val/stats-utils.mjs";
import stableSearchParams from "@/util/stable-url-params.mjs";

export default async (params, searchParams, state) => {
  const promises = [];
  const [constants] = await fetchConstants();
  const agentParams = getStatsParamsStr(searchParams, "agents");
  agentParams.sort();
  const key = btoa(stableSearchParams(agentParams));

  promises.push(
    getData(getStats(searchParams, constants, "agents"), ValorantStatsAgents, [
      "val",
      "stats",
      "agents",
      key,
    ]),
  );

  const regions = TOP_REGIONS.slice(0, NUMBER_OF_LEADERBOARDS_ON_HOMEPAGE);

  regions.map((region) =>
    promises.push(
      fetchData(params, new URLSearchParams(`region=${region}`), state),
    ),
  );

  await Promise.all(promises);
};
